import { useIntl } from 'react-intl';

import { OFFER_ROLES as ROLE, Person, INSURANCE_TYPE } from 'ppz-otr-common';
import styled from 'styled-components';

import { useTariff } from '../../../context/TariffCtx';
import commonLiterals from '../../../language/src/common';
import { sizes } from '../../../styles/tokens';
import { getBirthdate, isZEK, isZEZ } from '../../../utils';
import { SidebarText as Text, SidebarDate, SidebarPrices } from '../layouts';
import { literals } from '../literals';

export interface PersonDetailsProps {
  insuranceType: INSURANCE_TYPE;
  index: number;
  person: Person;
  idPrice: string;
  idNameDate: string;
}

const Wrapper = styled.div`
  margin: 0 0 ${sizes.space.PX_16};

  & > *:not(:last-child) {
    margin: 0 0 ${sizes.space.PX_08};
  }
`;

const PersonDetails = ({
  insuranceType,
  index,
  person,
  idPrice,
  idNameDate,
}: PersonDetailsProps) => {
  const { products } = useTariff();
  const { role, name, lastname, gender, tariffDetails } = person;

  const intl = useIntl();
  const isInsuranceOwner = role === ROLE.insuranceOwner;
  const salutation = !!gender
    ? intl.formatMessage({ id: literals[gender] })
    : '';
  const fullName = !!name && `${salutation} ${name} ${lastname}`;
  const tariffIsZEZorZEK = isZEZ(products) || isZEK(products);

  const getTextByRole = (): string => {
    const policyHolder = intl.formatMessage(commonLiterals.policyholder);
    const insuredPerson = intl.formatMessage(commonLiterals.insuredPerson);

    if (insuranceType === INSURANCE_TYPE.others) {
      if (isInsuranceOwner) return policyHolder;
      const insuredPersonText = tariffIsZEZorZEK
        ? insuredPerson
        : `${index - 1}. ${insuredPerson}`;
      return insuredPersonText;
    } else if (insuranceType === INSURANCE_TYPE.myselfAndMore) {
      if (isInsuranceOwner)
        return `${index}. ${insuredPerson} (${policyHolder})`;
      else return `${index}. ${insuredPerson}`;
    } else {
      return `${insuredPerson} (${policyHolder})`;
    }
  };

  /**
   *
   * @returns boolean
   * - a) If insuranceType === 'others' the price should be displayed only for insured persons this only applies if tariff !== ZEZ
   * - b) If insuranceType === 'myselfAndMore' the price should be displayed always
   * - c) If insuranceType === 'myself' the price should not be displayed
   *
   */
  const isPriceShown = (): boolean => {
    if (insuranceType === INSURANCE_TYPE.others) {
      return !isInsuranceOwner && !tariffIsZEZorZEK;
    } else {
      return insuranceType === INSURANCE_TYPE.myselfAndMore;
    }
  };

  return (
    <Wrapper>
      <div className="abTesting" id={idNameDate}>
        <Text
          variant="secondary"
          text={getTextByRole()}
          type="insuredPersonTitle"
        />
        {!!fullName && (
          <Text
            data-testid="sidebar__breakdown-person-name"
            text={`${fullName}`}
          />
        )}
        <SidebarDate type="birthdate" date={getBirthdate(person)} />
      </div>
      {isPriceShown() && (
        <div
          data-testid="sidebar__breakdown-prices"
          className="sidebar__breakdown-prices"
        >
          <SidebarPrices
            section="breakdown"
            start={tariffDetails?.startPrice}
            final={tariffDetails?.tariff}
            id={idPrice}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default PersonDetails;
