import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import styled from 'styled-components';

import literals from '../../../language/src/sidebar';
import { sizes } from '../../../styles/tokens';
import { SidebarPrice } from '../components';
import { SidebarSection, Tariff } from '../types';

import { SidebarText as Text } from './';

interface Props {
  section: SidebarSection;
  start: number | undefined;
  final: number | undefined;
  id: string;
  hideAsterisk?: boolean;
}

const SidebarPricesWrapper = styled.div`
  & > div:not(:last-child) {
    margin: 0 0 ${sizes.space.PX_06};
  }
`;

const SidebarPrices = ({ section, start, final, id, hideAsterisk }: Props) => {
  const intl = useIntl();

  const gotFinalPrice = useMemo(() => {
    return start !== final;
  }, [start, final]);

  const contributionPerMonth = intl.formatMessage(
    literals.summaryContributionPerMonth,
  );

  const getPrices = useMemo(
    () => (gotFinalPrice ? [start, final] : [start]),
    [final, gotFinalPrice, start],
  );

  const getTariff = (key: number): Tariff => {
    if (!gotFinalPrice) return 'common';
    return key === 0 ? 'start' : 'final';
  };

  return (
    <SidebarPricesWrapper className="abTesting abTestingOutBottom" id={id}>
      {section === 'summary' && (
        <div>
          <Text variant="secondary" text={contributionPerMonth} />
        </div>
      )}
      {getPrices.map((item, key) => (
        <SidebarPrice
          key={key}
          section={section}
          tariff={getTariff(key)}
          value={item || 0}
          hideAsterisk={hideAsterisk}
        />
      ))}
    </SidebarPricesWrapper>
  );
};

export default SidebarPrices;
