import { defineMessages } from 'react-intl';

export default defineMessages({
  checkBirthdateIzs: {
    id: 'birthdates.check.birthdate.izs',
    defaultMessage: 'Bitte überprüfen Sie Ihr Geburtsdatum.',
  },
  mySelfTitle: {
    id: 'birthdates.myself.title',
    defaultMessage: 'Bitte geben Sie Ihr Geburtsdatum an.',
  },
  insuredPersonTitle: {
    id: 'birthdates.insuredPerson.title',
    defaultMessage:
      'Bitte geben Sie das Geburtsdatum der zu versichernden Person an.',
  },
  mySelfSubTitle: {
    id: 'birthdates.myself.subtitle',
    defaultMessage: 'Ihr Alter ist notwendig, um Ihren Beitrag zu berechnen.',
  },
  mySelfOthersHint: {
    id: 'birthdates.myself.others.hint',
    defaultMessage: 'Sie sind Versicherungsnehmer.',
  },
  mySelfMySelfAndOthersHint: {
    id: 'birthdates.myself.myself-and-others.hint',
    defaultMessage: 'Sie sind auch Versicherungsnehmer.',
  },
  mySelfAndOthersHint: {
    id: 'birthdates.myself-and-others.hint',
    defaultMessage: 'Geburtsdatum der {numberOfPerson}. Person',
  },
  myselfAndOthersHintZEZ_ZEK: {
    id: 'birthdates.myself-and-others.hint-zez-zek',
    defaultMessage: 'Geburtsdatum der zu versichernden Person.',
  },
  mySelfOthersSubtitle: {
    id: 'birthdates.myself.others.subtitle',
    defaultMessage: '',
  },
  mySelfAndMoreTitle: {
    id: 'birthdates.myself-and-more.title',
    defaultMessage: 'Welche weiteren Personen möchten Sie versichern?',
  },
  mySelfAndMoreSubtitle: {
    id: 'birthdates.myself-and-more.subtitle',
    defaultMessage:
      'Sie können <bold>bis zu 3 weitere Personen</bold> in einem Vertrag absichern, z. B. Kinder, Geschwister, Eltern oder andere nahe Angehörige.',
  },
  othersTitle: {
    id: 'birthdates.other-people-only.title',
    defaultMessage: 'Welche Personen möchten Sie versichern?',
  },
  othersSubtitle: {
    id: 'birthdates.other-people-only.subtitle',
    defaultMessage:
      'Sie können <bold>bis zu 4 Personen</bold> in einem Vertrag absichern, z. B. Kinder, Geschwister, Eltern oder andere nahe Angehörige.',
  },
  addPersonButton: {
    id: 'birthdates.button.addPerson',
    defaultMessage: '+ Weitere Person hinzufügen',
  },
  validationVnAdult: {
    id: 'birthdates.validation.vn-adult',
    defaultMessage:
      'Die als Versicherungsnehmer eingetragene Person muss mindestens 18 Jahre alt sein.',
  },
  validationNotFuture: {
    id: 'birthdates.validation.not-future',
    defaultMessage: 'Das Geburtsdatum darf nicht in der Zukunft liegen.',
  },
  validationNotPast: {
    id: 'birthdates.validation.not-past',
    defaultMessage:
      'Wir können Ihnen diese Versicherung derzeit online nicht anbieten. Bitte wenden Sie sich für ein persönliches Angebot an unseren gebührenfreien Beratungs-Service.',
  },
  genericError: {
    id: 'birthdates.validation.generic-error',
    defaultMessage:
      'Bitte geben Sie ein gültiges Datum im Format TT.MM.JJJJ ein.',
  },
  tooltip: {
    id: 'birthdates.tooltip.content',
    defaultMessage:
      '<p><bold>Achtung</bold>: Bitte geben Sie als Versicherungsnehmer auf jeden Fall Ihr <bold>Geburtsdatum</bold> an. Auch dann, wenn Sie nicht zu den zu versichernden Personen gehören.</p><p>Als Versicherungsnehmer müssen Sie <bold>mindestens 18 Jahre alt</bold> sein.</p>',
  },
  tooltipZEZ_ZEK: {
    id: 'birthdates.tooltipZEZ_ZEK.content',
    defaultMessage:
      '<p><bold>Achtung</bold>: Bitte geben Sie als Versicherungsnehmer auf jeden Fall Ihr <bold>Geburtsdatum</bold> an. Auch dann, wenn Sie nicht die zu versichernde Person sind.</p><p>Als Versicherungsnehmer müssen Sie <bold>mindestens 18 Jahre alt</bold> sein.</p>',
  },
});
