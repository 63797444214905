import { defineMessages } from 'react-intl';

export default defineMessages({
  premiumTariffTitle: {
    id: 'contribution.premium.headline',
    defaultMessage: 'Wählen Sie Ihren Versicherungsschutz',
  },
  singleTariffTitle: {
    id: 'contribution.basic.headline',
    defaultMessage: 'Ihr gewählter Versicherungsschutz',
  },
  premiumTariffModalLink: {
    id: 'contribution.text-link.modal',
    defaultMessage: 'Alle Leistungen im Vergleich',
  },
  singleTariffModalLink: {
    id: 'contribution.text-link.modal.zez',
    defaultMessage: 'Alle Leistungen anzeigen',
  },
  pdfLink: {
    id: 'contribution.text-link.pdf',
    defaultMessage: 'Leistungsübersicht als PDF herunterladen',
  },
  proceed: {
    id: 'contribution.cta.contract',
    defaultMessage: 'Weiter zum Online-Abschluss',
  },
  request: {
    id: 'contribution.cta.offer',
    defaultMessage: 'Angebot anfordern',
  },
  preventionBasicLimitedUsp1: {
    id: 'contribution.prevention.limited.usp1',
    defaultMessage:
      '<bold>1x (je Versicherungsjahr)</bold> Prophylaxemaßnahmen inkl. prof. Zahnreinigung',
  },
  preventionBasicPremiumUsp2: {
    id: 'contribution.prevention.usp2',
    defaultMessage: '<bold>100%</bold> für Kunststofffüllungen',
  },
  preventionBasicPremiumUsp3: {
    id: 'contribution.prevention.usp3',
    defaultMessage:
      '<bold>100%</bold> für Wurzel-, Paradontose- und Schleimhautbehandlung',
  },
  preventionPremiumUnlimitedUsp1: {
    id: 'contribution.prevention.unlimited.usp1',
    defaultMessage:
      '<bold>100%</bold> Prophylaxemaßnahmen inkl. prof. Zahnreinigung',
  },
  protection75Usp1: {
    id: 'contribution.protection.75.usp1',
    defaultMessage: '<bold>75%</bold> für Implantate',
  },
  protection75Usp2: {
    id: 'contribution.protection.75.usp2',
    defaultMessage: '<bold>75%</bold> für Brücken, Kronen, Prothesen',
  },
  protection75Usp3: {
    id: 'contribution.protection.75.usp3',
    defaultMessage: '<bold>75%</bold> für Inlays, Onlays',
  },
  protection90Usp1: {
    id: 'contribution.protection.90.usp1',
    defaultMessage: '<bold>90%</bold> für Implantate',
  },
  protection90Usp2: {
    id: 'contribution.protection.90.usp2',
    defaultMessage: '<bold>90%</bold> für Brücken, Kronen, Prothesen',
  },
  protection90Usp3: {
    id: 'contribution.protection.90.usp3',
    defaultMessage: '<bold>90%</bold> für Inlays, Onlays',
  },

  protection100Usp1: {
    id: 'contribution.protection.100.usp1',
    defaultMessage: '<bold>100%</bold> für Implantate',
  },
  protection100Usp2: {
    id: 'contribution.protection.100.usp2',
    defaultMessage: '<bold>100%</bold> für Brücken, Kronen, Prothesen',
  },
  protection100Usp3: {
    id: 'contribution.protection.100.usp3',
    defaultMessage: '<bold>100%</bold> für Inlays, Onlays',
  },

  protectionZEFUsp1: {
    id: 'contribution.old.zef.usp1',
    defaultMessage: '<bold>Verdopplung des Festzuschusses</bold>',
  },
  protectionZEFUsp2: {
    id: 'contribution.old.zef.usp2',
    defaultMessage: '<bold>Schnelle Auszahlung</bold>',
  },
  protectionZEFUsp3: {
    id: 'contribution.old.zef.usp3',
    defaultMessage:
      'Aufnahme <bold>ohne Gesundheitsfragen</bold> und <bold>ohne Wartezeit</bold>',
  },
  protectionZZPUsp1: {
    id: 'contribution.old.zzp.usp1',
    defaultMessage: '<bold>Verdopplung des Festzuschusses</bold>',
  },
  protectionZZPUsp2: {
    id: 'contribution.old.zzp.usp2',
    defaultMessage: '<bold>Zusätzlich bis zu 500 € pro Implantat</bold>',
  },
  protectionZZPUsp3: {
    id: 'contribution.old.zzp.usp3',
    defaultMessage: '<bold>Aufnahme ohne Gesundheitsfragen</bold>',
  },
  protectionZEZUsp1: {
    id: 'contribution.old.zez.usp1',
    defaultMessage: '<bold>Verdopplung des Festzuschusses</bold>',
  },
  protectionZEZUsp2: {
    id: 'contribution.old.zez.usp2',
    defaultMessage:
      '<bold>Absicherung begonnener und zukünftiger Zahnersatzmaßnahmen</bold>',
  },
  protectionZEZUsp3: {
    id: 'contribution.old.zez.usp3',
    defaultMessage:
      'Aufnahme <bold>ohne Gesundheitsfragen</bold> und <bold>ohne Wartezeit</bold>',
  },

  tooltipsPrevention1: {
    id: 'contribution.tooltip.1',
    defaultMessage:
      '<bold>Prophylaxemaßnahmen</bold><br></br><br></br>• Professionelle Zahnreinigung (PZR)<br></br>• Erstellung des Mundhygienestatus: eingehende Untersuchung auf Zahn, Mund- und Kieferkrankheiten, Beurteilung der Mundhygiene und des Zahnfleischzustands, Kontrolle des Übungserfolgs einschließlich weiterer Unterweisungen <br></br>• Aufklärung über Krankheitsursachen der Zähne und deren Vermeidung<br></br>•	Fluoridierung zur Zahnschmelzhärtung   <br></br>•	Beseitigung von Zahnbelägen und Verfärbungen   <br></br>•	Behandlung überempfindlicher Zahnflächen   <br></br>•	Fissurenversiegelung',
  },
  tooltipsPrevention2: {
    id: 'contribution.tooltip.2',
    defaultMessage:
      '<bold>Kunststofffüllungen</bold><br></br><br></br>Zahnfarbene Füllungen, die natürlich aussehen und lang halten.',
  },
  tooltipsPrevention3: {
    id: 'contribution.tooltip.3',
    defaultMessage:
      '<bold>Wurzelbehandlung:</bold> Chirurgischer Eingriff an der Zahnwurzel mit dem Ziel, einen beschädigten Zahn zu erhalten.<br></br><br></br><bold>Parodontosebehandlung:</bold> Chirurgischer Eingriff an den Zahnfleischtaschen, um diese und den Bereich der Zahnwurzel zu reinigen und eine Parodontitis einzudämmen.<br></br><br></br><bold>Schleimhautbehandlung:</bold> Schleimhauttransplantation bei stark geschädigtem oder kaum noch vorhandenem Zahnfleisch.',
  },
  tooltipsProtection4: {
    id: 'contribution.tooltip.4',
    defaultMessage:
      '<bold>Implantat</bold><br></br><br></br>Künstliche Zahnwurzel aus Keramik oder Titan, die als Verankerung für implantatgetragenen Zahnersatz dient.',
  },
  tooltipsProtection5: {
    id: 'contribution.tooltip.5',
    defaultMessage:
      '<bold>Brücke</bold><br></br>Festsitzender Zahnersatz, der eine Lücke zwischen 2 Zähnen schließt. An diesen sog. Brückenpfeilern wird ein frei schwebender künstlicher Zahn befestigt, der Brückenkörper.<br></br><br></br><bold>Krone</bold><br></br>Prothetischer Überzug von Zähnen bzw. Implantaten. Er bedeckt den Zahn ganz oder teilweise (Teilkrone).<br></br><br></br><bold>Prothese</bold><br></br>Herausnehmbarer Zahnersatz aus Kunststoff oder Metall, an dem künstliche Zähne befestigt sind.<br></br><br></br>•	Teilprothesen ersetzen mehrere Zähne.   <br></br> •	Vollprothesen ersetzen alle Zähne.',
  },
  tooltipsProtection6: {
    id: 'contribution.tooltip.6',
    defaultMessage:
      '<bold>Inlays, Onlays</bold><br></br>Einlagefüllung aus Gold oder Vollkeramik.',
  },
  tooltipsZEZ1: {
    id: 'contribution.zez.tooltip.1',
    defaultMessage:
      'Wenn Ihre gesetzliche Krankenkasse einen Zuschuss zahlt, verdoppelt die ERGO Krankenversicherung AG diesen bis zu 100 % der Gesamtrechnung. Auch bei Behandlungen im Ausland, die von Ihrer Krankenkasse bezuschusst werden.',
  },
  tooltipsZEZ2: {
    id: 'contribution.zez.tooltip.2',
    defaultMessage:
      'Auch bei schon erstelltem Heil- und Kostenplan. Sogar fehlende Zähne sind mitversichert.',
  },
  tooltipsZEF1: {
    id: 'contribution.zef.tooltip.1',
    defaultMessage:
      'Wenn Ihre gesetzliche Krankenkasse einen Zuschuss zahlt, verdoppelt die ERGO Krankenversicherung AG diesen bis zu 100% der Gesamtrechnung. Auch bei Behandlungen im Ausland, die von Ihrer Krankenkasse bezuschusst werden.',
  },
  tooltipsZEF2: {
    id: 'contribution.zef.tooltip.2',
    defaultMessage:
      'Ihre Zahnarztrechnungen bis 500 € prüft ERGO meistens gleich am Telefon.',
  },
  tooltipsZZP1: {
    id: 'contribution.zzp.tooltip.1',
    defaultMessage:
      'Wenn Ihre gesetzliche Krankenkasse einen Zuschuss zahlt, verdoppelt die ERGO Krankenversicherung AG diesen bis zu 100% der Gesamtrechnung. Auch bei Behandlungen im Ausland, die von Ihrer Krankenkasse bezuschusst werden.',
  },
  tooltipsZZP2: {
    id: 'contribution.zzp.tooltip.2',
    defaultMessage:
      'Gilt unabhängig von einer Erstattung Ihrer gesetzlichen Krankenversicherung.',
  },
  tooltipsOld: {
    id: 'contribution.tooltip.7',
    defaultMessage: 'Old tariffs tooltip literal',
  },

  dentalPrevention: {
    id: 'product.dental-prevention',
    defaultMessage: 'Dental-Vorsorge',
  },
  dentalPreventionPremium: {
    id: 'product.dental-prevention-premium',
    defaultMessage: 'Dental-Vorsorge Premium',
  },
  dentalProtection75: {
    id: 'product.dental-protection-75',
    defaultMessage: 'Dental-Schutz 75',
  },
  dentalProtection90: {
    id: 'product.dental-protection-90',
    defaultMessage: 'Dental-Schutz 90',
  },
  dentalProtection100: {
    id: 'product.dental-protection-100',
    defaultMessage: 'Dental-Schutz 100',
  },
  dentalDoubleFixedSubsidyForDentures_ZEF: {
    id: 'product.doubleFixedSubsidyForDentures',
    defaultMessage: 'Dental-Zuschuss (ZEF)',
  },
  dentalDenturesWithImplants_ZZP: {
    id: 'product.denturesWithImplants',
    defaultMessage: 'Zahn-Zuschuss-Plus (ZZP)',
  },
  dentalDentalProsthesis_ZEZ: {
    id: 'product.prosthesisInsuranceWithoutWaitingPeriod',
    defaultMessage: 'Zahn-Ersatz-Sofort (ZEZ)',
  },
  dentalDentalProsthesis_ZEK: {
    id: 'product.prosthesisInsuranceWithoutWaitingPeriodZEK',
    defaultMessage: 'Zahnersatz Sofort (ZEK)',
  },
});
