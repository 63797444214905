import { ProductIds, OldProductIds } from 'ppz-otr-common';

export const basicProducts = [
  ProductIds.ZAHN_DS75,
  ProductIds.ZAHN_DS90,
  ProductIds.ZAHN_DVB,
  ProductIds.ZAHN_DVE,
];

export const premiumProducts = [
  ProductIds.ZAHN_DS75,
  ProductIds.ZAHN_DS90,
  ProductIds.ZAHN_DVB,
  ProductIds.ZAHN_DVE,
  ProductIds.ZAHN_DS100,
];

export const basicProductsDS100 = [
  ProductIds.ZAHN_DS75,
  ProductIds.ZAHN_DS90,
  ProductIds.ZAHN_DS100,
];
export const premiumProductsDS100 = [
  ProductIds.ZAHN_DS75,
  ProductIds.ZAHN_DS90,
  ProductIds.ZAHN_DS100,
  ProductIds.ZAHN_DVB,
];

export const basicProductsZEZ = [OldProductIds.ZAHN_ZEZ];
export const premiumProductsZEZ = [OldProductIds.ZAHN_ZEZ];

export const basicProductsZEK = [OldProductIds.ZAHN_ZEK];
export const premiumProductsZEK = [OldProductIds.ZAHN_ZEK];

export const basicProductsZEF = [OldProductIds.ZAHN_ZEF];
export const premiumProductsZEF = [OldProductIds.ZAHN_ZEF, ProductIds.ZAHN_DVB];

export const basicProductsZZP = [OldProductIds.ZAHN_ZZP];
export const premiumProductsZZP = [OldProductIds.ZAHN_ZZP, ProductIds.ZAHN_DVB];

export const personIdsMock: Map<string, boolean> = new Map([
  ['e06a7b1e-3ce5-43b0-94a8-19c079818a41', false],
  ['510ccd20-b79d-4dbd-a9f9-b9a956b449a6', false],
  ['3b68b2d6-72e7-4258-8008-7a2b833f6365', false],
  ['0fcae6b1-c11e-48de-9498-e781ecc7957d', false],
  ['2bb2e041-5ed8-4c92-a67f-249d638674b7', false],
  ['de353c64-1953-444b-b161-01e1e155a3e7', false],
  ['7be089f4-96f6-4ca8-9f71-4a5bc5c54016', false],
  ['6ebf27de-8078-4269-ba18-08a69bc13709', false],
  ['4096b841-a959-4fb9-bf7b-ff4bee82da4d', false],
  ['fac2bba1-3c76-48bb-a8e1-c7cc27372b58', false],
  ['ab57201a-1d53-4abd-8dba-8472083c833f', false],
  ['3581966a-cb8f-43e8-9245-ab674756e9da', false],
  ['560067fd-30e7-43cd-bf5b-fdd0b4ec520e', false],
  ['16a92d38-f7fb-47ba-bb3c-74d296ab1854', false],
  ['76b438a4-5aaa-46b1-acf9-58ce93658f56', false],
  ['98e7e5f9-d5ab-4067-86de-ad7c449abe64', false],
  ['dbace1b9-2d65-4e97-ba20-f44186ff4d2c', false],
  ['0608d168-3c35-417b-856e-c8c2c48b2c8d', false],
  ['df1109ad-ce8e-476b-aebb-22ce557df7e6', false],
  ['f60311ba-9012-437e-ad60-d769f7ed323b', false],
  ['ddc397da-48b2-4f79-861b-1f17d5f6689a', false],
  ['2d369219-22de-46c0-b835-d0783723c53e', false],
  ['571b8877-4645-4dfd-acf8-2532c5b571d8', false],
  ['f19910af-eb04-40d4-a075-51f88a731294', false],
  ['54b3cb35-29af-4f63-8c05-ecd88de2ef1f', false],
  ['ad4116e3-89a6-48f7-93ba-571f13640b80', false],
  ['8be7f353-7ed2-4bfe-b111-4e3ef92f92ca', false],
  ['a7c0e6be-362c-4fe7-a7d0-4c2aff00c06a', false],
  ['03b9b542-0af0-4387-b5bf-fae93eb6182f', false],
  ['42b72877-7047-4d99-8d8a-4637f8460509', false],
  ['5b62fee7-29a8-4f0b-946b-78dfffd0a8d3', false],
  ['6f482c56-c4af-4586-96a7-166bfc4f79d9', false],
]);
