import { useIntl } from 'react-intl';

import ABTestingIds from '../../../ABTesting/sidebar';
import { useTariff } from '../../../context/TariffCtx';
import literals from '../../../language/src/sidebar';
import { sizes } from '../../../styles/tokens';
import { isZEK } from '../../../utils';
import {
  SidebarText as Text,
  SidebarPrices as Prices,
  SidebarSection as Section,
  SidebarDate,
} from '../layouts';

interface Props {
  products?: string;
  tariff?: number;
  startTariff?: number;
  insuranceBegin?: string;
}

const Summary = ({ products, startTariff, tariff, insuranceBegin }: Props) => {
  const intl = useIntl();
  const { products: tariffProducts } = useTariff();

  const titleLegend = intl.formatMessage(literals.summaryTitle);

  const isZekTariff = isZEK(tariffProducts);

  return (
    <Section
      className="sidebar__summary abTesting abTestingOutLeft"
      id={ABTestingIds.summary}
    >
      <Text variant="primary" text={titleLegend} id={ABTestingIds.title} />
      <div className="abTesting" id={ABTestingIds.productNameAndBeginDate}>
        <Text variant="secondary" text={products} margin={sizes.space.PX_24} />
        {insuranceBegin && (
          <SidebarDate type="insurance" date={insuranceBegin} />
        )}
      </div>
      <Prices
        section="summary"
        start={startTariff}
        final={tariff}
        id={ABTestingIds.pricesWrapper}
        hideAsterisk={isZekTariff}
      />
    </Section>
  );
};

export default Summary;
