import {
  TariffPrices,
  Person,
  ProductIds,
  OldProductIds,
  Tariffs,
  isOldTariff,
} from 'ppz-otr-common';

import { PersonContext } from '../context/PersonCtx';
import { Products, VIEWS } from '../types';

import {
  aemComparisonProducts,
  productLabels,
  productUspRelationship,
  pdfSuffixProducts,
} from './productsData';

const productIdPrefix = 'ZAHN_';

const isZEZ = (products: Tariffs[]): boolean => {
  return products.includes(OldProductIds.ZAHN_ZEZ);
};

const isZEK = (products: Tariffs[]): boolean => {
  return products.includes(OldProductIds.ZAHN_ZEK);
};

const isZEKandInsuranceBeginningOrSummaryView = (
  products: Tariffs[],
  view: VIEWS,
): boolean => {
  return (
    isZEK(products) &&
    (view.includes(VIEWS.insuranceBeginning) || view.includes(VIEWS.summary))
  );
};

const filterAndSortProtectionProducts = (products: string[]): string[] => {
  return products
    .filter((prod) => isNaN(parseInt(prod)))
    .sort((a, b) => {
      return a === ProductIds.ZAHN_DVE.replace(productIdPrefix, '') ? -1 : 1;
    });
};
export const filterAndSortCoverageProducts = (products: string[]): string[] => {
  return products
    .filter((prod) => !isNaN(parseInt(prod)))
    .sort((a, b) => {
      return parseInt(b) - parseInt(a);
    });
};

const getCoverageName = (coverage: string[]): string => {
  return coverage.length > 0 ? 'DS' + coverage[0] : '';
};

const getProtectionName = (protection: string[]): string => {
  return protection.length > 0 ? protection[0] : '';
};

const getSomethingFromProductMap = <T>(
  products: Tariffs[],
  productMap: Map<Products, T>,
): T | undefined => {
  const selectedOldTariff = isOldTariff(products);

  const transformedProducts: string[] = products.map((prod) =>
    prod.replace(productIdPrefix, '').replace('DS', ''),
  );

  if (selectedOldTariff) {
    const keyNames = Object.keys(Products).find(
      (key) => key === transformedProducts.join('_'),
    );

    return keyNames ? productMap.get(Products[keyNames]) : undefined;
  }

  const protections: string[] =
    filterAndSortProtectionProducts(transformedProducts);

  const coverages: string[] =
    filterAndSortCoverageProducts(transformedProducts);

  const productKeyName: keyof Products | undefined = Object.keys(Products).find(
    (key) =>
      key ===
      [getProtectionName(protections), getCoverageName(coverages)]
        .filter((tariff) => tariff !== '')
        .join('_'),
  ) as keyof Products | undefined;

  return !!productKeyName
    ? productMap.get(Products[productKeyName])
    : undefined;
};

const getProductName = (products: Tariffs[]) =>
  getSomethingFromProductMap<string>(products, productLabels);

const getProductUsps = (products: Tariffs[]) =>
  getSomethingFromProductMap<string[]>(products, productUspRelationship);

const getAemProductComparison = (products: Tariffs[]) =>
  getSomethingFromProductMap<string>(products, aemComparisonProducts);

const getPDFSuffixProducts = (products: Tariffs[]) =>
  getSomethingFromProductMap<string>(products, pdfSuffixProducts);

const getSidebarDetails = (
  tariffSelected: TariffPrices,
  beginningDate: string | undefined = undefined,
  persons: PersonContext['persons'],
) => {
  const headerDetails = {
    insuranceBegin: beginningDate,
    startTariff: tariffSelected.initial,
    tariff: tariffSelected.total,
  };
  if (tariffSelected.perPerson !== undefined) {
    const personsDetailed: Person[] = Array.from(persons.values()).map(
      (singlePerson) => {
        const personDetailedTariff = tariffSelected.perPerson.filter(
          (tariff) => tariff.personId === singlePerson.id,
        );
        return {
          ...singlePerson,
          tariffDetails: {
            tariff:
              personDetailedTariff.length > 0
                ? personDetailedTariff[0].final
                : 0,
            startPrice:
              personDetailedTariff.length > 0
                ? personDetailedTariff[0].initial
                : 0,
          },
        };
      },
    );
    return {
      ...headerDetails,
      personTariffs: personsDetailed,
    };
  }

  return {
    ...headerDetails,
    personTariffs: [],
  };
};

export {
  getProductName,
  getProductUsps,
  getSidebarDetails,
  getAemProductComparison,
  getPDFSuffixProducts,
  isOldTariff,
  isZEZ,
  isZEK,
  isZEKandInsuranceBeginningOrSummaryView,
};
