import { rest } from 'msw';
import {
  INSURANCE_TYPE,
  InitOfferRequest,
  OldProductIds,
  Tariffs,
  ProductIds,
} from 'ppz-otr-common';

import { INIT_OFFER_API } from '../../services';
import storage, { LocalKeys } from '../../services/storage';
import { VIEWS } from '../../types';
import { getUrl, resDefault } from '../utils';

import {
  mySelf,
  mySelfAndOthers,
  mySelfDS100,
  mySelfAndOthersDS100,
  mySelfAndOthersZEF,
  mySelfAndOthersZEZ,
  mySelfAndOthersZZP,
  mySelfZEF,
  mySelfZEZ,
  mySelfZZP,
  mySelfZEK,
  mySelfAndOthersZEK,
} from './responses/initOffer';

const realStorage =
  typeof window !== 'undefined'
    ? storage
    : {
        get: (key: string) => {
          return key;
        },
      };

const getObjectWithResponses = (products: Tariffs[], isMySelf: boolean) => {
  const firstProductId = products[0];
  const isZEZ = firstProductId === OldProductIds.ZAHN_ZEZ;
  const isZEK = firstProductId === OldProductIds.ZAHN_ZEK;
  const isZEF = firstProductId === OldProductIds.ZAHN_ZEF;
  const isZZP = firstProductId === OldProductIds.ZAHN_ZZP;
  const isDS100 = firstProductId === ProductIds.ZAHN_DS100;

  if (isDS100) {
    return isMySelf ? mySelfDS100 : mySelfAndOthersDS100;
  }

  if (isZEZ) {
    return isMySelf ? mySelfZEZ : mySelfAndOthersZEZ;
  }

  if (isZEK) {
    return isMySelf ? mySelfZEK : mySelfAndOthersZEK;
  }

  if (isZEF) {
    return isMySelf ? mySelfZEF : mySelfAndOthersZEF;
  }

  if (isZZP) {
    return isMySelf ? mySelfZZP : mySelfAndOthersZZP;
  }

  return isMySelf ? mySelf : mySelfAndOthers;
};

const getResponse = (activeView: string, bodyRequest: InitOfferRequest) => {
  const { products } = bodyRequest;

  const insuranceSituation =
    realStorage.get(LocalKeys.insuranceSituation) ?? '';
  const isMySelf = insuranceSituation === INSURANCE_TYPE.myself;

  const responses = getObjectWithResponses(products, isMySelf);

  const { feedback, finalize, withAddresses, withBirthdates, initial } =
    responses;

  switch (activeView) {
    case VIEWS.myself:
    case VIEWS.myselfAndMore:
    case VIEWS.others:
    case VIEWS.insuranceBeginning:
    case VIEWS.contribution:
    case VIEWS.personalData:
      return withBirthdates;

    case VIEWS.summary:
    case VIEWS.bankDetails:
      return withAddresses;

    case VIEWS.finalize:
      return finalize;

    case VIEWS.feedback:
      return feedback;

    default:
      return initial;
  }
};

const initOfferUrl = getUrl(INIT_OFFER_API.POST);
const initOfferHandler = rest.post(initOfferUrl, async (req, res, ctx) => {
  const request = await req.json<InitOfferRequest>();
  const activeView = realStorage.get(LocalKeys.history) ?? '';
  return resDefault(
    ctx.status(200),
    ctx.json(getResponse(activeView, request)),
  );
});

export const handlers = [initOfferHandler];
export default handlers;
