import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'summary.title',
    defaultMessage: 'Sie haben es fast geschafft.',
  },
  subtitle: {
    id: 'summary.subtitle',
    defaultMessage:
      'Bitte prüfen Sie, ob Ihre Angaben richtig und vollständig sind.',
  },
  editButton: {
    id: 'summary.edit-button',
    defaultMessage: 'Angaben ändern',
  },
  detailsTariffTitle: {
    id: 'summary.accordion.1.title',
    defaultMessage: 'Tarif und Beitrag',
  },
  detailsTariffDes: {
    id: 'summary.accordion.1.tariff-des',
    defaultMessage: 'Gewählter Tarif',
  },
  detailsTariffUspList: {
    id: 'summary.accordion.1.tariff-list-usp',
    defaultMessage: 'Leistungen',
  },
  detailsTariffPrices: {
    id: 'summary.accordion.1.prices',
    defaultMessage: 'Beitrag',
  },
  detailsTariffStartPriceTitle: {
    id: 'summary.accordion.1.start-price-title',
    defaultMessage: 'Startbeitrag',
  },
  detailsTariffStartPriceDes: {
    id: 'summary.accordion.1.start-price',
    defaultMessage: 'monatlich (Startbeitrag 1. bis 6. Monat)*',
  },
  detailsTariffFinalPriceTitle: {
    id: 'summary.accordion.1.final-price-title',
    defaultMessage: 'Tarifbeitrag',
  },
  detailsTariffFinalPriceDes: {
    id: 'summary.accordion.1.final-price',
    defaultMessage: 'monatlich (Tarifbeitrag ab dem 7. Monat)*',
  },
  detailsTariffCommonPriceDes: {
    id: 'summary.accordion.1.common-price',
    defaultMessage: 'monatlich*',
  },
  detailsTariffCommonPriceDesWithoutAsterix: {
    id: 'summary.accordion.1.common-price_v2',
    defaultMessage: 'monatlich',
  },
  detailsBeginningDateTitle: {
    id: 'summary.accordion.2.title',
    defaultMessage: 'Versicherungsbeginn',
  },
  detailsBeginningDateDes: {
    id: 'summary.accordion.2.beginning-date',
    defaultMessage: 'Start der Versicherung',
  },
  detailsPersonSalutation: {
    id: 'summary.accordion.person.salutation',
    defaultMessage: 'Anrede',
  },
  detailsPersonTitleName: {
    id: 'summary.accordion.person.full-name',
    defaultMessage: 'Vorname, Name',
  },
  detailsPersonTitleAddress: {
    id: 'summary.accordion.person.address',
    defaultMessage: 'Adresse',
  },
  detailsPersonTitleBirthdate: {
    id: 'summary.accordion.person.birthdate',
    defaultMessage: 'Geburtsdatum',
  },
  detailsPersonTitleEmail: {
    id: 'summary.accordion.person.email',
    defaultMessage: 'E-Mail',
  },
  detailsPersonTitlePhone: {
    id: 'summary.accordion.person.phone',
    defaultMessage: 'Telefon / Mobilnummer',
  },
  detailsPersonTitleMySelfOwner: {
    id: 'summary.accordion.person.title-my-self.owner',
    defaultMessage: 'Persönliche Daten',
  },
  detailsPersonTitleTitleMySelfAndMoreOwner: {
    id: 'summary.accordion.person.title-my-self-and-more.owner',
    defaultMessage: 'Versicherungsnehmer und 1. zu versichernde Person',
  },
  detailsPersonTitleOthersOwner: {
    id: 'summary.accordion.person.title-others.owner',
    defaultMessage: 'Versicherungsnehmer',
  },
  detailsPersonTitlePersonTitle: {
    id: 'summary.accordion.person.title.person',
    defaultMessage: 'zu versichernde Person',
  },
  detailsPersonTitleUniquePersonTitle: {
    id: 'summary.accordion.person.title.unique-person',
    defaultMessage: 'Versicherte Person',
  },
});
