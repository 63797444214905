import productLiterals from '../../language/src/contribution';
import { Products } from '../../types';
import { getLiteral } from '../literals';

const productLabels = new Map<Products, string>();

productLabels.set(
  Products.DS75,
  getLiteral(productLiterals.dentalProtection75),
);
productLabels.set(
  Products.DS90,
  getLiteral(productLiterals.dentalProtection90),
);
productLabels.set(
  Products.DS100,
  getLiteral(productLiterals.dentalProtection100),
);
productLabels.set(Products.DVB, getLiteral(productLiterals.dentalPrevention));
productLabels.set(
  Products.DVE,
  getLiteral(productLiterals.dentalPreventionPremium),
);
productLabels.set(
  Products.DVB_DS75,
  `${getLiteral(productLiterals.dentalPrevention)} + ${getLiteral(
    productLiterals.dentalProtection75,
  )}`,
);
productLabels.set(
  Products.DVB_DS90,
  `${getLiteral(productLiterals.dentalPrevention)} + ${getLiteral(
    productLiterals.dentalProtection90,
  )}`,
);
productLabels.set(
  Products.DVB_DS100,
  `${getLiteral(productLiterals.dentalPrevention)} + ${getLiteral(
    productLiterals.dentalProtection100,
  )}`,
);
productLabels.set(
  Products.DVE_DS75,
  `${getLiteral(productLiterals.dentalPreventionPremium)} + ${getLiteral(
    productLiterals.dentalProtection75,
  )}`,
);
productLabels.set(
  Products.DVE_DS90,
  `${getLiteral(productLiterals.dentalPreventionPremium)} + ${getLiteral(
    productLiterals.dentalProtection90,
  )}`,
);
productLabels.set(
  Products.DVE_DS100,
  `${getLiteral(productLiterals.dentalPreventionPremium)} + ${getLiteral(
    productLiterals.dentalProtection100,
  )}`,
);

// old tariffs
productLabels.set(
  Products.ZEZ,
  getLiteral(productLiterals.dentalDentalProsthesis_ZEZ),
);
productLabels.set(
  Products.ZEK,
  getLiteral(productLiterals.dentalDentalProsthesis_ZEK),
);
productLabels.set(
  Products.ZZP,
  getLiteral(productLiterals.dentalDenturesWithImplants_ZZP),
);
productLabels.set(
  Products.ZZP_DVB,
  `${getLiteral(productLiterals.dentalDenturesWithImplants_ZZP)} + ${getLiteral(
    productLiterals.dentalPrevention,
  )}`,
);
productLabels.set(
  Products.ZEF,
  getLiteral(productLiterals.dentalDoubleFixedSubsidyForDentures_ZEF),
);
productLabels.set(
  Products.ZEF_DVB,
  `${getLiteral(
    productLiterals.dentalDoubleFixedSubsidyForDentures_ZEF,
  )} + ${getLiteral(productLiterals.dentalPrevention)}`,
);

export { productLabels };
