import React from 'react';

import Card from '@eg/elements/Card';

import { OldProductIds } from 'ppz-otr-common';

import { useTariff } from '../../../context/TariffCtx';
import { getProductName } from '../../../utils';
import { CustomProductName } from '../layouts';

import { CoverageList } from './CoverageList';
interface Props {
  gotPremium: boolean;
}
export const CustomCard = ({ gotPremium }: Props) => {
  const { products } = useTariff();

  const title = getProductName(products);
  const coverageListContent = products.includes(OldProductIds.ZAHN_ZEK)
    ? [OldProductIds.ZAHN_ZEZ]
    : products;

  return (
    <Card>
      <CustomProductName>{title}</CustomProductName>
      <CoverageList products={coverageListContent} gotPremium={gotPremium} />
    </Card>
  );
};
