import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { DateInputValue } from '@eg/elements/components/DateInput';

import {
  BirthdateData,
  OFFER_ROLES,
  INSURANCE_TYPE,
  ContributionDetails,
} from 'ppz-otr-common';

import ABTestingIds from '../../ABTesting/birthdates';
import { useDates } from '../../context/DatesCtx';
import { useInsurance } from '../../context/InsuranceCtx';
import { useLoading, useLoadingUpdate } from '../../context/LoadingCtx';
import {
  PersonContext,
  usePerson,
  usePersonUpdate,
} from '../../context/PersonCtx';
import { useTariff, useTariffUpdate } from '../../context/TariffCtx';
import { useResetContributionTariff, useTracking } from '../../hooks';
import literals from '../../language/src/birthdates';
import { SingleButton, VIEWS, ClickableElements } from '../../types';
import { isZEK, isZEZ } from '../../utils';

import BirthDatesInput from './components/BirthDatesInput';
import useBirthdateBackend, {
  CurrentValues,
  UpdateCurrentValues,
} from './hooks/useBirthdateBackend';
import useBirthdateState from './hooks/useBirthdateState';
import BirthdatesLayout from './layouts/BirthdatesLayout';
import { BirthdateMap } from './types';
import {
  CommonButtonsIds,
  ONBLUR_ACTION_TIMEOUT,
  getCleanPersons,
  getCommonButtons,
  getDefaultDateValue,
  getDefaultValues,
  getNewMapFromArr,
  hasErrors,
  onBlurDate,
  onChangeDate,
  onGenericError,
  sortDateValues,
} from './utils';

interface Props {
  type: INSURANCE_TYPE;
}

const InsuredPersonBirthdates = ({ type }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { businessId, situation } = useInsurance();
  const { dateValues, disabled, setDateValues, setDisabled } =
    useBirthdateState();
  const { setTrackingClick } = useTracking();
  const { validationDates } = useDates();
  const { max, min } = validationDates;
  const maxDate = new Date(max);
  const minDate = new Date(min);

  const { loading } = useLoading();
  const { setLoading } = useLoadingUpdate();

  const { products, premiumProducts, contributionSelected } = useTariff();
  const {
    setBasicTariffPrices,
    setTariffPriceSelected,
    setPremiumProducts,
    setPremiumTariffPrices,
  } = useTariffUpdate();

  const {
    persons,
    spcsPersons,
    insuranceOwnerId,
    maxInsuredPersons: MAX_INSURED_PERSONS,
  } = usePerson();
  const { setPersons, setSpcsPersons } = usePersonUpdate();

  const [forceSave, setForceSave] = useState(false);

  const requestTimeout = useRef<NodeJS.Timeout | null>(null);

  const updatePersons = (
    newPersons: PersonContext['persons'],
    newDateValues: React.SetStateAction<BirthdateMap>,
  ) => {
    setPersons(newPersons);
    setSpcsPersons(newPersons);
    setDateValues(newDateValues);
    setLoading(false);
  };

  const updateDateValues = (newDateValues: BirthdateMap) => {
    setDateValues(newDateValues);
    setDisabled(hasErrors(newDateValues));
    setLoading(false);
  };

  const updateTariffs = ({
    basicPrices,
    premiumPrices,
    premiumProducts,
  }: ContributionDetails) => {
    setBasicTariffPrices(basicPrices);
    if (contributionSelected === 'basic' || contributionSelected === '') {
      setTariffPriceSelected(basicPrices);
    } else {
      setTariffPriceSelected(premiumPrices);
    }
    setPremiumProducts(premiumProducts);
    setPremiumTariffPrices(premiumPrices);
  };

  const tariffZEZ_ZEK = isZEZ(products) || isZEK(products);

  const defaultValue: BirthdateData = getDefaultDateValue(
    OFFER_ROLES.insuredPerson,
  );

  const noInsuranceOwnerDates = Array.from(dateValues.values()).filter(
    ({ role }) => role !== OFFER_ROLES.insuranceOwner,
  );
  const disabledIncreaseDate =
    (type === INSURANCE_TYPE.myselfAndMore &&
      noInsuranceOwnerDates.length === MAX_INSURED_PERSONS - 1) ||
    (type === INSURANCE_TYPE.others &&
      noInsuranceOwnerDates.length === MAX_INSURED_PERSONS);

  const getDateLimit = () => {
    let maxInsuredPeople = MAX_INSURED_PERSONS;
    if (type === INSURANCE_TYPE.myselfAndMore) {
      return MAX_INSURED_PERSONS - 1;
    }

    return maxInsuredPeople;
  };

  const clearExistingTimeout = () => {
    if (!!requestTimeout.current) clearTimeout(requestTimeout.current);
    requestTimeout.current = null;
  };

  const increaseDateHandler = () => {
    clearExistingTimeout();
    const dateLimit = getDateLimit();
    const onInsuranceOwnerDates = Array.from(dateValues.values()).filter(
      ({ role }) => role !== OFFER_ROLES.insuranceOwner,
    );

    if (onInsuranceOwnerDates.length < dateLimit) {
      const newDates = new Map(dateValues);
      newDates.set(dateValues.size, { ...defaultValue, id: dateValues.size });

      // Tracking logic for passing birthdates Map
      const _birthDates = sortDateValues(newDates);
      const _insuredOwner = Array.from(_birthDates).find((item) => {
        const { role } = item[1];
        return role === OFFER_ROLES.insuranceOwner;
      });
      const _insuredPersons = Array.from(_birthDates).filter((item) => {
        const { role } = item[1];
        return role !== OFFER_ROLES.insuranceOwner;
      });
      const _sortedBirthDatesMap = new Map<
        string | number | null,
        BirthdateData
      >();
      _insuredOwner &&
        _sortedBirthDatesMap.set(_insuredOwner[0], _insuredOwner[1]);

      _insuredPersons.forEach((item) => {
        _sortedBirthDatesMap.set(item[0], item[1]);
      });

      setTrackingClick(ClickableElements.ButtonAddPerson, {
        type: 'BirthdateData',
        value: _sortedBirthDatesMap,
      });
      // End of tracking logic

      setDateValues(newDates);
      setDisabled(true);
      setForceSave(true);
    }
  };

  const decreaseDateHandler = (key: BirthdateData['id']) => {
    clearExistingTimeout();
    const insuranceOwnerDate: BirthdateData = dateValues.get(insuranceOwnerId)!;
    let newDates: BirthdateData[] = Array.from(dateValues.values())
      .filter(
        ({ role, id }) => role !== OFFER_ROLES.insuranceOwner && id !== key,
      )
      .map((date, ind) => ({
        ...date,
        id: isNaN(date.id as number) ? date.id : ind,
      }));

    // Tracking logic for passing birthdates Map
    const _birthdates = [
      insuranceOwnerDate,
      ...newDates.filter((item) => item.role !== OFFER_ROLES.insuranceOwner),
    ];

    if (_birthdates[0]) {
      const _birthdatesMap = new Map(
        _birthdates.map((item) => [item.id, item]),
      );
      setTrackingClick(ClickableElements.ButtonDeletePerson, {
        type: 'BirthdateData',
        value: _birthdatesMap,
      });
    }
    // End of tracking logic

    newDates = newDates.concat(insuranceOwnerDate);
    const newDateValues: BirthdateMap = getNewMapFromArr(
      newDates,
    ) as BirthdateMap;

    setDateValues(newDateValues);
    setDisabled(hasErrors(newDateValues));
    setForceSave(true);
  };

  const onBack = () => {
    clearExistingTimeout();
    setTrackingClick(ClickableElements.ButtonBack);
    saveChanges(() => navigate(VIEWS.myself));
  };

  const doNavigation = () => {
    navigate(VIEWS.insuranceBeginning);
  };

  const genericError = () => onGenericError(setLoading, navigate);

  const birthdateBackendData: CurrentValues = {
    businessId,
    dateValues,
    persons,
    spcsPersons,
    situation,
    type,
    initialProducts: {
      basic: products,
      premium: premiumProducts,
    },
    maxDate,
    minDate,
    intl,
  };
  const birthdateBackendUpdater: UpdateCurrentValues = {
    updatePersons,
    updateDateValues,
    updateTariffs,
    setLoading,
    genericError,
  };

  const { saveChanges } = useBirthdateBackend(
    birthdateBackendData,
    birthdateBackendUpdater,
  );

  const onNext = async () => {
    clearExistingTimeout();
    setTrackingClick(ClickableElements.ButtonNext);

    const cleanPersons: PersonContext['persons'] = getCleanPersons(persons);

    // Sort persons because insuranceOwner should go first always
    const sortedPersons = new Map(
      [...cleanPersons.entries()].sort((a, b) => {
        return a[1].role === OFFER_ROLES.insuranceOwner ? -1 : 1;
      }),
    );
    updatePersons(sortedPersons, dateValues);
    await saveChanges(doNavigation);
  };

  const onChange = (
    date: DateInputValue,
    errors: any,
    key: BirthdateData['id'],
  ) => {
    const newDateValues = onChangeDate(dateValues, date, errors, key);
    setDateValues(newDateValues);
    setDisabled(hasErrors(newDateValues));
  };

  const onBlur = () => {
    onBlurDate(dateValues, setDisabled);

    clearExistingTimeout();
    requestTimeout.current = setTimeout(() => {
      setForceSave(true);
    }, ONBLUR_ACTION_TIMEOUT);
  };

  const getDescription = (index: number) => {
    if (tariffZEZ_ZEK) {
      return intl.formatMessage(literals.myselfAndOthersHintZEZ_ZEK);
    }

    return intl.formatMessage(
      { ...literals.mySelfAndOthersHint },
      {
        numberOfPerson:
          situation === INSURANCE_TYPE.myselfAndMore ? index + 1 : index,
      },
    ) as string;
  };

  const buttonIds: CommonButtonsIds = {
    next: ABTestingIds.insuredNextButton,
    back: ABTestingIds.insuredBackButton,
  };

  const commonButtons: SingleButton[] = getCommonButtons(
    disabled,
    onNext,
    onBack,
    intl,
    buttonIds,
  );

  const addPersonButton: SingleButton = {
    label: intl.formatMessage(literals.addPersonButton),
    type: 'secondary',
    callbackOnClick: increaseDateHandler,
    disabled: disabledIncreaseDate,
    id: ABTestingIds.addPersonButton,
  };

  const buttonList: SingleButton[] = [...commonButtons];

  // Add button only if there are more than 1 person
  if (MAX_INSURED_PERSONS > 1) {
    buttonList.unshift(addPersonButton);
  }

  const pageSubtitle = tariffZEZ_ZEK
    ? ''
    : intl.formatMessage({
        id: `birthdates.${type}.subtitle`,
      });

  useResetContributionTariff(genericError);

  useEffect(() => {
    return () => {
      clearExistingTimeout();
    };
  }, []);

  useEffect(() => {
    const { newDates, disabled } = getDefaultValues(
      type,
      persons,
      defaultValue,
      maxDate,
      minDate,
    );
    setDateValues(newDates);
    setDisabled(disabled);
  }, []);

  useEffect(() => {
    if (forceSave) {
      setForceSave(false);
      saveChanges();
    }
  }, [forceSave]);

  return (
    <BirthdatesLayout
      title={intl.formatMessage(literals.insuredPersonTitle)}
      titleId={ABTestingIds.insuredTitle}
      subtitle={pageSubtitle}
      buttonsList={buttonList}
      buttonListId={ABTestingIds.insuredButtonContainer}
      loading={loading}
    >
      {validationDates.min !== '' &&
        Array.from(dateValues.values())
          .filter(({ role }) => role !== OFFER_ROLES.insuranceOwner)
          .map((data, index, dateArr) => {
            const showIcon = {
              show: MAX_INSURED_PERSONS > 1,
              disabled: index === 0 && dateArr.length === 1,
            };
            return (
              <BirthDatesInput
                key={`dates-${data.id}`}
                description={getDescription(index + 1)}
                icon={showIcon}
                value={data}
                onDateChange={onChange}
                onDecrease={decreaseDateHandler}
                onBlur={onBlur}
                minDate={minDate}
                maxDate={maxDate}
                yesterdayDate={maxDate}
                id={`${ABTestingIds.insuredPrefixDate}${index}`}
              />
            );
          })}
    </BirthdatesLayout>
  );
};

export default InsuredPersonBirthdates;
