import { useIntl } from 'react-intl';

import Annotation from '@eg/elements/Annotation';
import Price from '@eg/elements/Price';

import styled from 'styled-components';

import { breakpoints, sizes } from '../../../styles/tokens';
import { literals } from '../literals';
import { Tariff, SidebarSection } from '../types';

interface Props {
  section: SidebarSection;
  tariff: Tariff;
  value: number;
  hideAsterisk?: boolean;
}

const PriceWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${sizes.text.S};

  @media (min-width: ${breakpoints.M}) {
    font-size: ${sizes.text.SM};
  }
`;

const PriceLabel = styled.span`
  font-size: ${sizes.text.S};

  @media (min-width: ${breakpoints.M}) {
    font-size: ${sizes.text.SM};
  }
`;

const AnnotationHierarchy = 1; // https://elements-t.cloudapps.local.ergodirekt.de/version/latest/#!/AnnotationProvider

const SidebarPrice = (props: Props) => {
  const { section, tariff, value } = props;

  const intl = useIntl();

  const label = intl.formatMessage({ id: literals.tariff[section][tariff] });

  return (
    <PriceWrapper {...props}>
      <PriceLabel>
        {label}
        {!props.hideAsterisk && <Annotation order={AnnotationHierarchy} />}
      </PriceLabel>
      <Price value={value} />
    </PriceWrapper>
  );
};

export default SidebarPrice;
