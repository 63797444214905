import { SHA256 } from 'crypto-js';
import {
  Person,
  BirthdateData,
  SpcsSalutation,
  OFFER_ROLES,
  INSURANCE_TYPE,
} from 'ppz-otr-common';

import { Gender, TrackingUser } from '../../../types';

import { PHONE_NUMBER_PREFIXES, USER_REPLACEMENT_REGEX } from './consts';

type PersonMap = Map<string | number | null, Person>;

export const sortPersons = (_persons: PersonMap): PersonMap => {
  const _insuredOwner = Array.from(_persons).find((item) => {
    const { role } = item[1];
    return role === OFFER_ROLES.insuranceOwner;
  });

  const _insuredPersons = Array.from(_persons).filter((item) => {
    const { role } = item[1];
    return role !== OFFER_ROLES.insuranceOwner;
  });

  const _sortedPersons = new Map<string | number | null, Person>();

  _insuredOwner && _sortedPersons.set(_insuredOwner[0], _insuredOwner[1]);
  _insuredPersons.map((item) => _sortedPersons.set(item[0], item[1]));

  return _sortedPersons;
};

export const getPersonsFromBirthdates = (
  birthdates: any,
): Map<string | number | null, Person> => {
  const _birthdates = Array.from(birthdates as BirthdateData[]).map((item) => {
    const { id, errors, ...rest }: BirthdateData = item[1];
    return { id, ...rest };
  });

  const _persons = new Map<number | string | null, Person>();

  _birthdates.forEach(({ id, ...props }) =>
    _persons.set(id, {
      id,
      ...props,
      address: {
        city: null,
        postalCode: null,
        street: null,
        streetNumber: null,
      },
      diffAddress: false,
      gender: null,
      lastname: null,
      name: null,
      tariffDetails: null,
    }),
  );

  return _persons;
};

export const getTrackingUsers = (
  persons: Map<string | number | null, Person>,
  situation: INSURANCE_TYPE,
): TrackingUser[] => {
  const filledPersons = Array.from(persons).filter(
    (item) => typeof item[0] === 'string',
  );
  const emptyPersons = Array.from(persons).filter(
    (item) => typeof item[0] === 'number',
  );

  const personsArray = [...filledPersons, ...emptyPersons];

  if (!personsArray.length) {
    return [
      {
        profile: {
          attributes: {},
        },
      },
    ];
  }

  return personsArray.map((_person) => {
    const person = _person[1];
    const isOnlyOthers = situation === INSURANCE_TYPE.others;
    const isInsuranceOwner = person.role === OFFER_ROLES.insuranceOwner;
    const isInsuredPerson =
      !isInsuranceOwner || (isInsuranceOwner && !isOnlyOthers);

    const _insuredIsInsuredPerson = isInsuredPerson ? 'J' : 'N';
    const _gender = person.gender === SpcsSalutation.Ms ? Gender.Ms : Gender.Mr;
    return {
      profile: {
        attributes: {
          insuredIsInsuredPerson: person.role ? _insuredIsInsuredPerson : '',
          birthday: person.year ?? 0,
          gender: person.gender ? _gender : null,
        },
        address: {
          line1: hashData(
            `${person.address.street}${person.address.streetNumber}`,
          ),
          city: hashData(`${person.address.city}`),
          postalCode: person.address.postalCode,
        },
        profileInfo: {
          ...(person.email
            ? person.email && { email: hashData(`${person.email}`) }
            : person.emailOffer && { email: hashData(`${person.emailOffer}`) }),
          ...(person.phone && {
            telephone: hashData(
              `${PHONE_NUMBER_PREFIXES.german}${preparePhoneNumberForHashing(
                person.phone,
              )}`,
            ),
            telephoneE164: hashData(
              `${PHONE_NUMBER_PREFIXES.e164}${preparePhoneNumberForHashing(
                person.phone,
              )}`,
            ),
          }),
          firstName: hashData(`${person.name}`),
          lastName: hashData(`${person.lastname}`),
        },
      },
    };
  });
};

export const preparePhoneNumberForHashing = (phoneNumber: string) =>
  phoneNumber.replace(USER_REPLACEMENT_REGEX.phone, '');

export const hashData = (dataToHash: string) => {
  const preparedDataToHash = dataToHash
    .toLowerCase()
    .replace(USER_REPLACEMENT_REGEX.hash, '');

  return SHA256(preparedDataToHash).toString();
};
