import {
  InitOfferResponse,
  OFFER_ROLES,
  SpcsSalutation,
  Person,
} from 'ppz-otr-common';

import {
  contributionDetailsInitial,
  contributionDetails,
  contributionDetailsDS100,
  contributionDetailsZEZ,
  contributionDetailsInitialZEF,
  contributionDetailsInitialZEZ,
  contributionDetailsZEF,
  contributionDetailsInitialZZP,
  contributionDetailsZZP,
} from '../../common/responses';
import {
  contributionDetailsInitialDS100,
  contributionDetailsInitialZEK,
  contributionDetailsZEK,
} from '../../common/responses/contributionDetails';
import { personIdsMock } from '../../constants';

const [firstId, secondId] = personIdsMock.keys();

const insuranceOwnerWithDate: Person = {
  id: firstId,
  day: '01',
  month: '01',
  year: '1980',
  address: {
    city: null,
    postalCode: null,
    street: null,
    streetNumber: null,
  },
  gender: null,
  name: null,
  lastname: null,
  role: OFFER_ROLES.insuranceOwner,
  bankdata: null,
  tariffDetails: null,
  emailOffer: null,
  diffAddress: false,
};

const insuranceOwnerWithAddress: Person = {
  id: firstId,
  day: '01',
  month: '01',
  year: '1980',
  address: {
    city: 'Halle (Saale)',
    postalCode: '06116',
    street: 'Messestr.',
    streetNumber: '8',
  },
  gender: SpcsSalutation.Ms,
  name: 'Aida',
  lastname: 'Perez',
  role: OFFER_ROLES.insuranceOwner,
  bankdata: null,
  tariffDetails: null,
  emailOffer: null,
  diffAddress: false,
};

const insuranceOwnerFull: Person = {
  id: firstId,
  day: '01',
  month: '01',
  year: '1980',
  address: {
    city: 'Halle (Saale)',
    postalCode: '06116',
    street: 'Messestr.',
    streetNumber: '8',
  },
  gender: SpcsSalutation.Ms,
  name: 'Aida',
  lastname: 'Perez',
  role: OFFER_ROLES.insuranceOwner,
  bankdata: {
    id: '83bf0f4a-2dc5-4202-a223-b85db4499815',
    iban: 'DE75500105178583737589',
    bic: 'INGDDEFFXXX',
    financialInstitutionName: 'ING-DiBa Frankfurt am Main',
    city: 'Frankfurt am Main',
    mrn: null,
  },
  tariffDetails: null,
  emailOffer: null,
  diffAddress: false,
  email: 'example@example.com',
};

const insuredPersonWithDate: Person = {
  id: secondId,
  day: '02',
  month: '02',
  year: '2002',
  address: {
    city: null,
    postalCode: null,
    street: null,
    streetNumber: null,
  },
  gender: null,
  name: null,
  lastname: null,
  role: OFFER_ROLES.insuredPerson,
  bankdata: null,
  tariffDetails: null,
  emailOffer: null,
  diffAddress: false,
};

const insuredPersonWithAddress: Person = {
  id: secondId,
  day: '02',
  month: '02',
  year: '2002',
  address: {
    city: 'Halle (29006)',
    postalCode: '29006',
    street: 'Alcala',
    streetNumber: '1',
  },
  gender: SpcsSalutation.Mr,
  name: 'Juan',
  lastname: 'Perez',
  role: OFFER_ROLES.insuranceOwner,
  bankdata: null,
  tariffDetails: null,
  emailOffer: null,
  diffAddress: false,
};

const bankDetails: InitOfferResponse['bankDetails'] = {
  bankname: 'ING-DiBa Frankfurt am Main',
  bic: 'INGDDEFFXXX',
  iban: 'DE75500105178583737589',
  accountHolder: 'Aida Perez',
};

type InitOfferResponseBase = Pick<
  InitOfferResponse,
  | 'businessId'
  | 'beginningDates'
  | 'insuranceDates'
  | 'bankDetails'
  | 'persons'
  | 'submitted'
>;

const responseBase: InitOfferResponseBase = {
  businessId: 'ff55d529-2f36-47ab-9724-a42f6806e19a',
  beginningDates: [
    '01.11.2022',
    '01.12.2022',
    '01.01.2023',
    '01.02.2023',
    '01.03.2023',
    '01.04.2023',
  ],
  insuranceDates: { min: '1922-10-06', max: '2022-10-05', adult: '2004-10-06' },
  bankDetails: null,
  persons: [],
  submitted: false,
};

const response: InitOfferResponse = {
  ...responseBase,
  insuredPersonsRanges: { min: 1, max: 4 },
  contributionDetails: contributionDetailsInitial,
};

const responseDS100: InitOfferResponse = {
  ...responseBase,
  insuredPersonsRanges: { min: 1, max: 4 },
  contributionDetails: contributionDetailsInitialDS100,
};

const responseZEZ: InitOfferResponse = {
  ...responseBase,
  insuredPersonsRanges: { min: 1, max: 1 },
  contributionDetails: contributionDetailsInitialZEZ,
};

const responseZEK: InitOfferResponse = {
  ...responseBase,
  insuredPersonsRanges: { min: 1, max: 1 },
  contributionDetails: contributionDetailsInitialZEK,
};

const responseZEF: InitOfferResponse = {
  ...responseBase,
  insuredPersonsRanges: { min: 1, max: 4 },
  contributionDetails: contributionDetailsInitialZEF,
};

const responseZZP: InitOfferResponse = {
  ...responseBase,
  insuredPersonsRanges: { min: 1, max: 4 },
  contributionDetails: contributionDetailsInitialZZP,
};

const responseMySelf: InitOfferResponse = {
  ...response,
  contributionDetails,
  persons: [insuranceOwnerWithDate],
};

const responseMySelfDS100: InitOfferResponse = {
  ...responseDS100,
  contributionDetails: contributionDetailsDS100,
  persons: [insuranceOwnerWithDate],
};

const responseMySelfZEZ: InitOfferResponse = {
  ...responseZEZ,
  contributionDetails: contributionDetailsZEZ,
  persons: [insuranceOwnerWithDate],
};

const responseMySelfZEK: InitOfferResponse = {
  ...responseZEK,
  contributionDetails: contributionDetailsZEK,
  persons: [insuranceOwnerWithDate],
};

const responseMySelfZEF: InitOfferResponse = {
  ...responseZEF,
  contributionDetails: contributionDetailsZEF,
  persons: [insuranceOwnerWithDate],
};

const responseMySelfZZP: InitOfferResponse = {
  ...responseZZP,
  contributionDetails: contributionDetailsZZP,
  persons: [insuranceOwnerWithDate],
};

const responseMySelfAndOthers: InitOfferResponse = {
  ...response,
  contributionDetails,
  persons: [insuranceOwnerWithDate, insuredPersonWithDate],
};

const responseMySelfAndOthersDS100: InitOfferResponse = {
  ...responseDS100,
  contributionDetails: contributionDetailsDS100,
  persons: [insuranceOwnerWithDate, insuredPersonWithDate],
};

const responseMySelfAndOthersZEZ: InitOfferResponse = {
  ...responseZEZ,
  contributionDetails: contributionDetailsZEZ,
  persons: [insuranceOwnerWithDate, insuredPersonWithDate],
};

const responseMySelfAndOthersZEK: InitOfferResponse = {
  ...responseZEK,
  contributionDetails: contributionDetailsZEK,
  persons: [insuranceOwnerWithDate, insuredPersonWithDate],
};

const responseMySelfAndOthersZEF: InitOfferResponse = {
  ...responseZEF,
  contributionDetails: contributionDetailsZEF,
  persons: [insuranceOwnerWithDate, insuredPersonWithDate],
};

const responseMySelfAndOthersZZP: InitOfferResponse = {
  ...responseZZP,
  contributionDetails: contributionDetailsZZP,
  persons: [insuranceOwnerWithDate, insuredPersonWithDate],
};

const responseSummaryMySelf: InitOfferResponse = {
  ...responseMySelf,
  persons: [insuranceOwnerWithAddress],
};

const responseSummaryMySelfDS100: InitOfferResponse = {
  ...responseMySelfDS100,
  persons: [insuranceOwnerWithAddress],
};

const responseSummaryMySelfZEZ: InitOfferResponse = {
  ...responseMySelfZEZ,
  persons: [insuranceOwnerWithAddress],
};

const responseSummaryMySelfZEK: InitOfferResponse = {
  ...responseMySelfZEK,
  persons: [insuranceOwnerWithAddress],
};

const responseSummaryMySelfZEF: InitOfferResponse = {
  ...responseMySelfZEF,
  persons: [insuranceOwnerWithAddress],
};

const responseSummaryMySelfZZP: InitOfferResponse = {
  ...responseMySelfZZP,
  persons: [insuranceOwnerWithAddress],
};

const responseSummaryMySelfAndOthers: InitOfferResponse = {
  ...responseMySelf,
  persons: [insuranceOwnerWithAddress, insuredPersonWithAddress],
};

const responseSummaryMySelfAndOthersDS100: InitOfferResponse = {
  ...responseMySelfDS100,
  persons: [insuranceOwnerWithAddress, insuredPersonWithAddress],
};

const responseSummaryMySelfAndOthersZEZ: InitOfferResponse = {
  ...responseMySelfZEZ,
  persons: [insuranceOwnerWithAddress, insuredPersonWithAddress],
};

const responseSummaryMySelfAndOthersZEK: InitOfferResponse = {
  ...responseMySelfZEK,
  persons: [insuranceOwnerWithAddress, insuredPersonWithAddress],
};

const responseSummaryMySelfAndOthersZEF: InitOfferResponse = {
  ...responseMySelfZEF,
  persons: [insuranceOwnerWithAddress, insuredPersonWithAddress],
};

const responseSummaryMySelfAndOthersZZP: InitOfferResponse = {
  ...responseMySelfZZP,
  persons: [insuranceOwnerWithAddress, insuredPersonWithAddress],
};

const responseFinalizeMySelf: InitOfferResponse = {
  ...responseMySelf,
  bankDetails,
  persons: [insuranceOwnerFull],
  submitted: false,
};

const responseFinalizeMySelfDS100: InitOfferResponse = {
  ...responseMySelfDS100,
  bankDetails,
  persons: [insuranceOwnerFull],
  submitted: false,
};

const responseFinalizeMySelfZEZ: InitOfferResponse = {
  ...responseMySelfZEZ,
  bankDetails,
  persons: [insuranceOwnerFull],
  submitted: false,
};

const responseFinalizeMySelfZEK: InitOfferResponse = {
  ...responseMySelfZEK,
  bankDetails,
  persons: [insuranceOwnerFull],
  submitted: false,
};

const responseFinalizeMySelfZEF: InitOfferResponse = {
  ...responseMySelfZEF,
  bankDetails,
  persons: [insuranceOwnerFull],
  submitted: false,
};

const responseFinalizeMySelfZZP: InitOfferResponse = {
  ...responseMySelfZZP,
  bankDetails,
  persons: [insuranceOwnerFull],
  submitted: false,
};

const responseFinalizeMySelfAndOthers: InitOfferResponse = {
  ...responseMySelf,
  bankDetails,
  persons: [insuranceOwnerFull, insuredPersonWithAddress],
  submitted: false,
};

const responseFinalizeMySelfAndOthersDS100: InitOfferResponse = {
  ...responseMySelfDS100,
  bankDetails,
  persons: [insuranceOwnerFull, insuredPersonWithAddress],
  submitted: false,
};

const responseFinalizeMySelfAndOthersZEZ: InitOfferResponse = {
  ...responseMySelfZEZ,
  bankDetails,
  persons: [insuranceOwnerFull, insuredPersonWithAddress],
  submitted: false,
};

const responseFinalizeMySelfAndOthersZEK: InitOfferResponse = {
  ...responseMySelfZEK,
  bankDetails,
  persons: [insuranceOwnerFull, insuredPersonWithAddress],
  submitted: false,
};

const responseFinalizeMySelfAndOthersZEF: InitOfferResponse = {
  ...responseMySelfZEF,
  bankDetails,
  persons: [insuranceOwnerFull, insuredPersonWithAddress],
  submitted: false,
};

const responseFinalizeMySelfAndOthersZZP: InitOfferResponse = {
  ...responseMySelfZZP,
  bankDetails,
  persons: [insuranceOwnerFull, insuredPersonWithAddress],
  submitted: false,
};

const responseFeedbackMySelf: InitOfferResponse = {
  ...responseFinalizeMySelf,
  submitted: true,
};

const responseFeedbackMySelfDS100: InitOfferResponse = {
  ...responseFinalizeMySelfDS100,
  submitted: true,
};

const responseFeedbackMySelfZEZ: InitOfferResponse = {
  ...responseFinalizeMySelfZEZ,
  submitted: true,
};

const responseFeedbackMySelfZEK: InitOfferResponse = {
  ...responseFinalizeMySelfZEK,
  submitted: true,
};

const responseFeedbackMySelfZEF: InitOfferResponse = {
  ...responseFinalizeMySelfZEF,
  submitted: true,
};

const responseFeedbackMySelfZZP: InitOfferResponse = {
  ...responseFinalizeMySelfZZP,
  submitted: true,
};

const responseFeedbackMySelfAndOthers: InitOfferResponse = {
  ...responseFinalizeMySelfAndOthers,
  submitted: true,
};

const responseFeedbackMySelfAndOthersDS100: InitOfferResponse = {
  ...responseFinalizeMySelfAndOthersDS100,
  submitted: true,
};

const responseFeedbackMySelfAndOthersZEZ: InitOfferResponse = {
  ...responseFinalizeMySelfAndOthersZEZ,
  submitted: true,
};

const responseFeedbackMySelfAndOthersZEK: InitOfferResponse = {
  ...responseFinalizeMySelfAndOthersZEK,
  submitted: true,
};

const responseFeedbackMySelfAndOthersZEF: InitOfferResponse = {
  ...responseFinalizeMySelfAndOthersZEF,
  submitted: true,
};

const responseFeedbackMySelfAndOthersZZP: InitOfferResponse = {
  ...responseFinalizeMySelfAndOthersZZP,
  submitted: true,
};

export interface DifferentInitOfferResponses {
  withBirthdates: InitOfferResponse;
  withAddresses: InitOfferResponse;
  finalize: InitOfferResponse;
  feedback: InitOfferResponse;
  initial: InitOfferResponse;
}

export const mySelf: DifferentInitOfferResponses = {
  withBirthdates: responseMySelf,
  withAddresses: responseSummaryMySelf,
  finalize: responseFinalizeMySelf,
  feedback: responseFeedbackMySelf,
  initial: response,
};

export const mySelfAndOthers: DifferentInitOfferResponses = {
  withBirthdates: responseMySelfAndOthers,
  withAddresses: responseSummaryMySelfAndOthers,
  finalize: responseFinalizeMySelfAndOthers,
  feedback: responseFeedbackMySelfAndOthers,
  initial: response,
};

export const mySelfDS100: DifferentInitOfferResponses = {
  withBirthdates: responseMySelfDS100,
  withAddresses: responseSummaryMySelfDS100,
  finalize: responseFinalizeMySelfDS100,
  feedback: responseFeedbackMySelfDS100,
  initial: responseDS100,
};

export const mySelfAndOthersDS100: DifferentInitOfferResponses = {
  withBirthdates: responseMySelfAndOthersDS100,
  withAddresses: responseSummaryMySelfAndOthersDS100,
  finalize: responseFinalizeMySelfAndOthersDS100,
  feedback: responseFeedbackMySelfAndOthersDS100,
  initial: responseDS100,
};

export const mySelfZEZ: DifferentInitOfferResponses = {
  withBirthdates: responseMySelfZEZ,
  withAddresses: responseSummaryMySelfZEZ,
  finalize: responseFinalizeMySelfZEZ,
  feedback: responseFeedbackMySelfZEZ,
  initial: responseZEZ,
};

export const mySelfAndOthersZEZ: DifferentInitOfferResponses = {
  withBirthdates: responseMySelfAndOthersZEZ,
  withAddresses: responseSummaryMySelfAndOthersZEZ,
  finalize: responseFinalizeMySelfAndOthersZEZ,
  feedback: responseFeedbackMySelfAndOthersZEZ,
  initial: responseZEZ,
};

export const mySelfZEK: DifferentInitOfferResponses = {
  withBirthdates: responseMySelfZEK,
  withAddresses: responseSummaryMySelfZEK,
  finalize: responseFinalizeMySelfZEK,
  feedback: responseFeedbackMySelfZEK,
  initial: responseZEK,
};

export const mySelfAndOthersZEK: DifferentInitOfferResponses = {
  withBirthdates: responseMySelfAndOthersZEK,
  withAddresses: responseSummaryMySelfAndOthersZEK,
  finalize: responseFinalizeMySelfAndOthersZEK,
  feedback: responseFeedbackMySelfAndOthersZEK,
  initial: responseZEK,
};

export const mySelfZEF: DifferentInitOfferResponses = {
  withBirthdates: responseMySelfZEF,
  withAddresses: responseSummaryMySelfZEF,
  finalize: responseFinalizeMySelfZEF,
  feedback: responseFeedbackMySelfZEF,
  initial: responseZEF,
};

export const mySelfAndOthersZEF: DifferentInitOfferResponses = {
  withBirthdates: responseMySelfAndOthersZEF,
  withAddresses: responseSummaryMySelfAndOthersZEF,
  finalize: responseFinalizeMySelfAndOthersZEF,
  feedback: responseFeedbackMySelfAndOthersZEF,
  initial: responseZEF,
};

export const mySelfZZP: DifferentInitOfferResponses = {
  withBirthdates: responseMySelfZZP,
  withAddresses: responseSummaryMySelfZZP,
  finalize: responseFinalizeMySelfZZP,
  feedback: responseFeedbackMySelfZZP,
  initial: responseZZP,
};

export const mySelfAndOthersZZP: DifferentInitOfferResponses = {
  withBirthdates: responseMySelfAndOthersZZP,
  withAddresses: responseSummaryMySelfAndOthersZZP,
  finalize: responseFinalizeMySelfAndOthersZZP,
  feedback: responseFeedbackMySelfAndOthersZZP,
  initial: responseZZP,
};
