import { ProductIds, OldProductIds, Tariffs } from 'ppz-otr-common';

import { DEFAULT_TARIFF } from './environment';

let defaultProducts: Tariffs[];

const defaultPremiumProducts: Tariffs[] = [
  ProductIds.ZAHN_DS100,
  ProductIds.ZAHN_DVE,
];
const defaultBasicProducts: Tariffs[] = [
  ProductIds.ZAHN_DS75,
  ProductIds.ZAHN_DVB,
];
const defaultOldProducts: Tariffs[] = [OldProductIds.ZAHN_ZEK];

switch (DEFAULT_TARIFF) {
  case 'basic':
    defaultProducts = defaultBasicProducts;
    break;
  case 'old':
    defaultProducts = defaultOldProducts;
    break;

  default:
    defaultProducts = defaultPremiumProducts;
    break;
}

export { defaultProducts };
