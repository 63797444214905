import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'personal-data.title',
    defaultMessage: 'Ergänzen Sie Ihre persönlichen Daten',
  },
  subtitle: {
    id: 'personal-data.subtitle',
    defaultMessage:
      'Das geht einfach mit wenigen Angaben. Vor dem Abschluss können Sie Ihre persönlichen Daten noch einmal prüfen.',
  },
  consentTitle: {
    id: 'consent-receive-information.title',
    defaultMessage:
      'Einwilligung zum Erhalt von Informationen zu Versicherungs- und Finanzprodukten der ERGO Group**',
  },
  consentEmailDescription: {
    id: 'consent-receive-information.email.description',
    defaultMessage:
      'Durch die Eingabe meiner E-Mail-Adresse willige ich ein, dass ich künftig per elektronischer Post über Versicherungs- und Finanzprodukte von Unternehmen und Vermittlern der ERGO Group** informiert werde. Sie können mich auch zur Kundenbefragung kontaktieren. Meine Daten dürfen hierfür verarbeitet werden. Diese Einwilligung gilt unabhängig davon, ob ein Vertrag besteht. Ich kann sie jederzeit formlos für die Zukunft widerrufen.',
  },
  consentPhoneDescription: {
    id: 'consent-receive-information.phone-number.description',
    defaultMessage:
      'Durch die Eingabe meiner Telefonnummer willige ich ein, dass ich künftig telefonisch über Versicherungs- und Finanzprodukte von Unternehmen und Vermittlern der ERGO Group** informiert werde. Sie können mich auch zur Kundenbefragung kontaktieren. Meine Daten dürfen hierfür verarbeitet werden. Diese Einwilligung gilt unabhängig davon, ob ein Vertrag besteht. Ich kann sie jederzeit formlos für die Zukunft widerrufen.',
  },
  consentPhoneLabel: {
    id: 'consent-receive-information.phone-label',
    defaultMessage: 'Telefon / Mobilnummer',
  },
  consentPhonePlaceholder: {
    id: 'consent-receive-information.phone-placeholder',
    defaultMessage: '+49 30 123456',
  },
  consentPhoneFormatError: {
    id: 'consent-receive-information.phone-error',
    defaultMessage: 'Bitte überprüfen Sie die Telefonnummer.',
  },
  consentSmallPrint: {
    id: 'consent-receive-information.small-print',
    defaultMessage:
      'ERGO Lebensversicherung AG, ERGO Versicherung AG, DKV Deutsche Krankenversicherung AG, ERGO Beratung und Vertrieb AG, ERGO Krankenversicherung AG, ERGO Direkt Versicherung AG, ERGO Life S.A., ERGO Vorsorge Lebensversicherung AG, ERGO Reiseversicherung AG, ERGO Direkt AG',
  },
  tooltipLabel: {
    id: 'personal-data.tooltip.label',
    defaultMessage: 'Datenschutz bei ERGO',
  },
  contractButton: {
    id: 'personal-data.button.contract',
    defaultMessage: 'Weiter zum Online-Abschluss',
  },
  offerButton: {
    id: 'personal-data.button.offer',
    defaultMessage: 'Angebot anfordern',
  },
  offerByEmailButton: {
    id: 'personal-data-appointment.button.offer-email',
    defaultMessage: 'Angebot per E-Mail',
  },
  offerByMailButton: {
    id: 'personal-data-appointment.button.offer-post',
    defaultMessage: 'Angebot per Post',
  },
  downloadOfferButton: {
    id: 'personal-data-appointment.button.offer-download',
    defaultMessage: 'Sofort-Download',
  },
  offerByEmailTitle: {
    id: 'personal-data.offer-by-email.title',
    defaultMessage: 'Warum benötigt ERGO Ihre E-Mail-Adresse?',
  },
  offerByEmailDescription: {
    id: 'personal-data.offer-by-email.description',
    defaultMessage:
      'ERGO benötigt Ihre E-Mail-Adresse, um Ihnen das Angebot zukommen zu lassen – unverbindlich und kostenlos. Tipp: Verwenden Sie eine E-Mail Adresse, die Sie regelmäßig nutzen.',
  },
  diffAddress: {
    id: 'personal-data-form.diff.address',
    defaultMessage: 'Andere Adresse als Versicherungsnehmer',
  },
  editBirthdate: {
    id: 'personal-data-form.edit.birthdate',
    defaultMessage: 'Angaben ändern',
  },
  labelName: {
    id: 'personal-data-form.label-name',
    defaultMessage: 'Vorname',
  },
  emptyErrorName: {
    id: 'personal-data-form.error-name-missing',
    defaultMessage: 'Bitte geben Sie Ihren Vornamen an.',
  },
  formatErrorName: {
    id: 'personal-data-form.error-name-format',
    defaultMessage: 'Bitte prüfen Sie Ihre Angaben zum Vornamen.',
  },
  lengthErrorName: {
    id: 'personal-data-form.error-name-length',
    defaultMessage: 'Der Vorname muss aus mind. 2 Buchstaben bestehen.',
  },

  labelLastName: {
    id: 'personal-data-form.label-lastname',
    defaultMessage: 'Name',
  },
  emptyErrorLastName: {
    id: 'personal-data-form.error-lastname-missing',
    defaultMessage: 'Bitte geben Sie Ihren Nachnamen an.',
  },
  formatErrorLastName: {
    id: 'personal-data-form.error-lastname-format',
    defaultMessage: 'Bitte prüfen Sie Ihre Angaben zum Nachnamen.',
  },
  lengthErrorLastName: {
    id: 'personal-data-form.error-lastname-length',
    defaultMessage: 'Der Nachname muss aus mind. 2 Buchstaben bestehen.',
  },
  addressNotValidError: {
    id: 'personal-data-form.error-address-not-valid',
    defaultMessage: 'Bitte prüfen Sie Ihre Angaben zur Adresse.',
  },
  addressAgentError: {
    id: 'personal-data-form.error-address-agent',
    defaultMessage:
      'Ihre Adresse konnte nicht verarbeitet werden. Rufen Sie bitte an.',
  },
  streetEmptyError: {
    id: 'personal-data-form.error-street-missing',
    defaultMessage: 'Bitte geben Sie Ihre Straße an.',
  },
  streetLabel: {
    id: 'personal-data-form.label-address-street',
    defaultMessage: 'Straße',
  },
  streetNumberEmptyError: {
    id: 'personal-data-form.error-number-missing',
    defaultMessage: 'Bitte geben Sie Ihre Hausnummer an.',
  },
  streetNumberLabel: {
    id: 'personal-data-form.label-address-number',
    defaultMessage: 'Nr.',
  },
  zipCodeError: {
    id: 'personal-data-form.error-zipcode',
    defaultMessage: 'Bitte geben Sie eine gültige Postleitzahl an.',
  },
  zipCodeLabel: {
    id: 'personal-data-form.label-address-zipcode',
    defaultMessage: 'PLZ',
  },
  zipCodeEmptyPlaceholder: {
    id: 'personal-data-form.placeholder-address-zipcode',
    defaultMessage: 'Wohnort wird automatisch ermittelt',
  },
  salutationEmptyError: {
    id: 'personal-data-form.error-salutation-missing',
    defaultMessage: 'Bitte wählen Sie die Anrede aus.',
  },
  salutationLabel: {
    id: 'personal-data-form.gender-group-label',
    defaultMessage: 'Anrede',
  },
  salutationFemaleLabel: {
    id: 'personal-data-form.label-salutation-female',
    defaultMessage: 'Frau',
  },
  salutationMaleLabel: {
    id: 'personal-data-form.label-salutation-masculine',
    defaultMessage: 'Herr',
  },
  ownerTitle: {
    id: 'personal-data-form.insurance-owner-title',
    defaultMessage: 'Persönliche Daten des Versicherungsnehmers',
  },
  uniqueBeneficiaryTitle: {
    id: 'personal-data-form.unique-insurance-beneficiary-title',
    defaultMessage: 'Persönliche Daten der zu versichernden Person',
  },
  beneficiaryTitle: {
    id: 'personal-data-form.insurance-beneficiary-title',
    defaultMessage: 'Persönliche Daten der {person}. zu versichernden Person',
  },
  addressCityLabel: {
    id: 'personal-data-form.label-address-city',
    defaultMessage: 'ORT',
  },
  addressGroupLabel: {
    id: 'personal-data-form.address-group-label',
    defaultMessage: 'Adresse',
  },
  addressAutoCorrected: {
    id: 'personal-data-form.address-auto-corrected',
    defaultMessage:
      'Ihre Eingabe wurde korrigiert. Bitte prüfen Sie, ob es sich um Ihre richtige Anschrift handelt',
  },
});
