import { TariffPrices, ProductIds } from 'ppz-otr-common';

export enum Products {
  /** DS75 */
  DS75,
  /** DS75/DS90 */
  DS90,
  /** DS75/DS90/DS100 */
  DS100,
  /** DVB */
  DVB,
  /** DVB/DVE */
  DVE,
  /** DVB + DS75 */
  DVB_DS75,
  /** DVB + DS75/DS90 */
  DVB_DS90,
  /** DVB + DS75/DS90/DS100 */
  DVB_DS100,
  /** DVB/DVE + DS75 */
  DVE_DS75,
  /** DVB/DVE + DS75/DS90 */
  DVE_DS90,
  /** DVB/DVE + DS75/DS90/DS100 */
  DVE_DS100,
  /** ZEK */
  ZEK,
  /** ZEZ */
  ZEZ,
  /** ZZP */
  ZZP,
  /** ZZP + DVB */
  ZZP_DVB,
  /** ZEF */
  ZEF,
  /** ZEF + DVB */
  ZEF_DVB,
}

export type Selection = 'basic' | 'premium' | '';

export interface PremiumDetails {
  products: ProductIds[];
  tariffs: TariffPrices;
}
